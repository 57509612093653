<template>
  <section class="message task-created">
    <section>
      <hub-icon name="checkbox-multiple-blank-outline" size="xlg" />
      <div>
        <h4>{{ metadata.task?.title }}</h4>
        <div class="status">
          Status: <label>{{ metadata.task?.status }}</label>
        </div>
        <div class="assignees">
          <em><label>Assignees</label>: {{ metadata.task?.assignees?.filter(ra => ra) || [] }}</em>
        </div>
      </div>
      <div class="metadata">
        <div>
          {{ new Date(createdAt).toLocaleString() }}
        </div>
        <div v-if="metadata.task?.dueAt">
          Due At <label>{{ new Date(metadata.task?.dueAt).toLocaleDateString() }}</label>
        </div>
        <div v-else>
          <em>No due date</em>
        </div>
        <div>
          <em
            >By <label>{{ createdBy }}</label></em
          >
        </div>
      </div>
      <div>
        <hub-button variant="icon" @click="$emit('click')"><hub-icon name="information-outline"></hub-icon></hub-button>
      </div>
    </section>
  </section>
</template>

<script>
import Button from './../../common/Button';
import Icon from './../../common/Icon';

export default {
  components: {
    'hub-button': Button,
    'hub-icon': Icon
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    createdBy: {
      type: String,
      required: true
    },
    createdAt: {
      type: [Date, String],
      required: true
    },
    metadata: {
      type: Object,
      default: () => null
    }
  },
  emits: ['click']
};
</script>

<style lang="scss" scoped>
.message.task-created {
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  height: 100%;
  width: 100%;
  background-color: var(--theme-surface);
  border-radius: 3px;
  padding: 0.75rem 1rem 0.75rem 1.25rem;
  grid-gap: 0.25rem;

  label {
    font-weight: 600;
  }

  em {
    font-size: 0.65rem;
    font-style: italic;
    color: var(--theme-on-background-accent);
  }

  > section {
    display: grid;
    grid-template-columns: 50px minmax(0, 1fr) max-content max-content;
    grid-template-rows: max-content;
    grid-gap: 0.5rem;
    align-items: center;

    h4 {
      font-weight: 700;
    }
  }

  .metadata {
    text-align: right;
    font-size: 0.65rem;
  }

  i {
    cursor: pointer;
  }
}
</style>
